<template>
    <div class="pageMain">
      <div class="flex">
        <!-- 组织项目树 -->
  
        <OriginTreeCompany class="tree" :filterText="filterText" @checked="init">
          <template slot="search">
            <SearchLeft @search="search" @reset="reset"   
              info="项目信息"
              :iconShow="false">
              <el-form :inline="true" :model="searchForm">
                <el-form-item label="" class="formItem">
                  <el-input
                  v-model="filterText"
                    size="small"
                    placeholder="项目"
                  ></el-input>
                </el-form-item>
              </el-form>
            </SearchLeft>
          </template>
        </OriginTreeCompany>
  
        <!--右边列表  -->
        <div class="form">
          <SearchHead
            @search="search"
            @reset="reset"
           
          >
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="设备类型">
                  <el-select
              class="block-select"
                v-model="searchForm.deviceType"
                size="small"
                placeholder="设备类型"
              >
                <el-option
                  v-for="item in deviceType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
        </el-form-item>
              <!-- <el-form-item label="现场编号">
                <el-input
                  v-model="searchForm.user"
                  size="small"
                  placeholder="现场编号"
                ></el-input>
              </el-form-item> -->
              
              <el-form-item label="主机IMEI">
                <el-input
                  v-model="searchForm.deviceImei"
                  size="small"
                  placeholder="主机IMEI"
                ></el-input>
              </el-form-item>
           
            </el-form>
          </SearchHead>
          <CustomTables
            :data="tableData"
            :headerCellStyle="headerCellStyle"
            @selectionChange="handleSelectionChange"
            @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
            :total="total"
            title="设备运行"
          >
            <!-- <template slot="btns">
              <el-button
                type="success"
                size="small"
                @click="addFrom"
                icon="el-icon-plus"
               
                class="btn"
                >抓拍</el-button
              >
            </template> -->
           
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="compName" label="公司名称" align="center">
            </el-table-column>
            <el-table-column prop="deviceCount" label="设备数" align="center">
            </el-table-column>
            <el-table-column prop="deviceOnlineCount" label="在线设备数" align="center">
            </el-table-column>
            <el-table-column prop="illegalCount" label="违章数" align="center">
            </el-table-column>
            <el-table-column prop="alarmCount" label="报警数" align="center">
            </el-table-column>
            
            <!-- <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
                <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              查看
            </span>
          
            </template>
          </el-table-column> -->
            
          </CustomTables>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import OriginTreeCompany from "../../../components/common/OriginTreeCompany.vue";
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import monitor from '../../../api/modules/monitor'
  import {equipType} from '../../../config/dataStatus'
  export default {
    components: {
      OriginTreeCompany,
      SearchHead,
      CustomTables,
      SearchLeft,
    },

    data() {
      return {
        searchForm: {
          pageNum: 1,
          pageSize: 10,
        },
        tableData: [
         
        ],
        deviceType:equipType,
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        total: 0,
        show: true,
        filterText:''
      };
    },
    mounted() {},
    methods: {
      addFrom() {},
      init(val) {
        this.searchForm.orgaId = val.orgaId;
        this.searchForm.currPage = 1;
        this.orgaId=val.orgaId;
        this.getList();
      },
      getList() {
        monitor.getDeviceRunListByPage(this.searchForm).then((res) => {
          this.total = res.data.total;
          this.tableData = res.data.records;
        });
     
      },
      search() {
        console.log("searchForm", this.searchForm);
        this.searchForm.pageNum = 1;
        this.searchForm.pageSize = 10;
        this.getList();
      },
      reset() {
        this.searchForm = {
          pageNum: 1,
          pageSize: 10,
          orgaId:this.orgaId
        };
        this.getList();
      },
      tableExport() {
        // 导出
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      detailItem(){
          //详情
      },
      deleteItem(){},
      handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
    },
  };
  </script>
  
  <style scoped lang="less">
 .flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
  .form {
    width: 78%;
    margin-left: 2%;
  }
  .formItem {
    margin-bottom: 0px;
  }
  .btn {
    background-color: #008f4d;
    // color: #008f4d;
  }
  
  .detail{
      color: #008f4d;
  }
  </style>
  