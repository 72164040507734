export const roleState =[
    {
        value:0,
        name: '使用'
    },{
        value:1,
        name: '停用'
    },{
        value:2,
        name: '删除'
    },
]
export const presetState =[
    {
        value:0,
        name: '是'
    },{
        value:1,
        name: '否'
    }
]
export const sexState = [
    {
        value:0,
        name: '男'
    },{
        value:1,
        name: '女'
    }
]
export const projectState =[
    {
        value:0,
        name: '正常'
    },{
        value:1,
        name: '停工'
    },{
        value:2,
        name: '完结'
    },
]
export const timeState = [
    {
        value:5,
        name: '5S'
    },{
        value:10,
        name: '10S'
    }
]
export const sexList = [
    {
        value:0,
        name: '男'
    },{
        value:1,
        name: '女'
    }
]
export const useStaate = [
    {
        value:0,
        name: '正常'
    },{
        value:1,
        name: '异常'
    }
]
export const equipType = [
    {
        value:0,
        name: '塔吊'
    },{
        value:1,
        name: '升降机'
    },
    {
        value:2,
        name: '龙门吊'
    },
    {
        value:3,
        name: '架桥机'
    },
    {
        value:4,
        name: '爬模'
    },
    {
        value:5,
        name: '挂篮'
    },
    {
        value:8,
        name: '扬尘'
    },
    {
        value:9,
        name: '喷淋'
    },
    
]
export const equipTypes = [
     {
    value:0,
     name: '塔吊'
       },{
       value:1,
        name: '塔式起重机'
       },
       {
        value:2,
      name: '门式起重机'
         },
         {
        value:3,
       name: '桥机'
         },
         {
        value:4,
        name: '升降机'
         },
       {
        value:5,
         name: '架桥机'
         },
        {
         value:5,
         name: '摄像头'
        },
         {
        value:5,
         name: '挂篮'
         },
    
]
export const deviceState = [
    {
        value:0,
        name: '使用'
    },{
        value:1,
        name: '闲置'
    },
    {
        value:2,
        name: '损坏'
    },
]
export const deviceStates = [
   {
        value:1,
        name: '闲置'
    },
   
]
export const progTypeList = [
    {
        value:0,
        name: '手机端'
    },{
        value:1,
        name: 'pc端'
    },
   
]
export const cameTypeList = [
    {
        value:0,
        name: '萤石云'
    },{
        value:2,
        name: '博瓦'
    },
    {
        value:1,
        name: '自有'
    },
   
]
export const cameStatusList = [
    {
        value:0,
        name: '使用 '
    },{
        value:1,
        name: '闲置'
    },
   
]
export const useState =[
    {
        value:0,
        name: '闲置'
    },{
        value:1,
        name: '使用'
    },{
        value:2,
        name: '拆除'
    },
]
export const busiState =[
    {
        value:0,
        name: '正常'
    },{
        value:1,
        name: '停用'
    },{
        value:2,
        name: '损坏'
    },
]
export const busiType =[
    {
        value:0,
        name: '业主方'
    },{
        value:1,
        name: '租赁方'
    },{
        value:2,
        name: '施工方'
    },
    {
        value:3,
        name: '监管方'
    },
]
export const deviceOnline =[
    {
        value:0,
        name: '在线'
    },{
        value:1,
        name: '离线'
    }
]
export const generationList =[
    {
        value:5,
        name: '五代机'
    },{
        value:6,
        name: '六代机'
    }
]
export const videoList =[
    { value: 1, name: '驾驶舱' },
    { value: 2, name: '卷扬' },
    { value: 3, name: '吊点' },
    { value: 5, name: '前天车卷扬' },
    { value: 6, name: '前天车吊点' },
    { value: 7, name: '后天车卷扬' },
    { value: 8, name: '后天车吊点' },
    { value: 9, name: '前行走区域' },
    { value: 10, name: '后行走区域' },
    { value: 11, name: '主起升吊点' },
    { value: 12, name: '辅助起升吊点' },
    { value: 13, name: '主起升卷扬' },
    { value: 14, name: '辅助起升卷扬' },
    { value: 15, name: '顶升' },
    { value: 16, name: '前右' },
    { value: 17, name: '后左' },
    { value: 18, name: '后右' },
    { value: 19, name: '前左' },
    { value: 20, name: '行程' },
    { value: 21, name: '破绳' },   
]
